
:root {
  --padding: 14px;

  --primary-h-s: 220, 12%;
  --primary-accent-h-s: 220, 90%;
  --secondary-h-s: 224, 15.3%;
  --secondary-accent-h-s: 224, 70%;
  

  --l-0: 2%;
  --l-10: 10%;
  --l-15: 15%;
  --l-20: 20%;
  --l-30: 25%;
  --l-35: 30%;
  --l-40: 40%;
  --l-50: 50%;
  --l-60: 60%;
  --l-70: 70%;
  --l-80: 80%;
  --l-90: 90%;
  --l-100: 98%;

  --led-shadow: #000;
  
}

/*
// @media (prefers-color-scheme: light) {
// 	:root {
//     // Inverted for light color scheme
//     --l-0: 98%;
//     --l-10: 90%;
//     --l-15: 87%;
//     --l-20: 85%;
//     --l-30: 75%;
//     --l-35: 70%;
//     --l-40: 60%;
//     --l-50: 50%;
//     --l-60: 40%;
//     --l-70: 30%;
//     --l-80: 20%;
//     --l-90: 10%;
//     --l-100: 2%;

//     --led-shadow: #777;
// 	}
// }
*/

body {
  background: hsla(var(--primary-h-s), var(--l-10), 1);
  color: hsla(var(--primary-h-s), var(--l-70), 1);
  font-family: 'Inter', -apple-system, BlinkMacSystemFont, "Segoe UI", Roboto, Oxygen, Ubuntu, Cantarell, "Open Sans",
    "Helvetica Neue", sans-serif;
  margin: 0;
  padding: 0;
  font-size: 0.875rem;
  line-height: 1.4;
}

h1, h2, h3, h4, h5, h6 {
  color: hsla(var(--primary-h-s), var(--l-90), 1);
}

a:link,  a:hover, a:active {
  font-weight: 600;
  color: hsl(var(--secondary-accent-h-s), var(--l-70));
}

a:visited {
  font-weight: 600;
  color: hsl(var(--secondary-h-s), var(--l-90));
}

* {
  box-sizing: border-box;
}

input,
textarea,
select,
option {
  font-family: 'Inter', -apple-system, BlinkMacSystemFont, "Segoe UI", Roboto, Oxygen, Ubuntu, Cantarell, "Open Sans",
    "Helvetica Neue", sans-serif;
}

button {
  padding: calc(var(--padding) / 2) var(--padding);
  background: hsl(var(--primary-h-s), var(--l-20));
  border: 1px solid hsl(var(--primary-h-s), var(--l-20));
  border-radius: 8px;
  font-size: 0.875rem !important;
  font-weight: 500;
  display: flex;
  align-items: center;
}

button:hover, button:active, button:focus {
  cursor: pointer;
  background: hsl(var(--primary-h-s), var(--l-30));
}

button span, button ion-icon {
  font-size: 0.875rem !important;
}

button ion-icon:first-child {
  margin-right: 8px;
}

button ion-icon:last-child {
  margin-left: 8px;
}

/* Markdown */

.markdown {
  * {
    font-size: 1rem;
    line-height: 1.7;
    color: hsla(var(--primary-h-s), var(--l-80), 1);
  }

  p {
    margin: calc(2 * var(--padding)) 0px;
  }

  hr {
    margin: calc(2 * var(--padding)) 0px;
    border-top: 1px solid hsl(var(--primary-h-s), var(--l-50));
    border-bottom: none;
    border-left: none;
    border-right: none;
  }

  img {
    width: 100%;
  }

  strong, h1, h2, h3, h4, h5, h6 {
    color: hsl(var(--primary-h-s), var(--l-90));
    margin: 0;
  }

  h1 { font-size: 2rem; }
  h2 { font-size: 1.75rem; }
  h3 { font-size: 1.5rem; }
  h4 { font-size: 1.25rem; }
  h5 { font-size: 1rem; }
  h6 { font-size: .875rem; }

  blockquote:not(.twitter-tweet) {
    border-top: 2px solid hsl(var(--secondary-h-s), var(--l-30));
    border-right: 2px solid hsl(var(--secondary-h-s), var(--l-30));
    border-bottom: 2px solid hsl(var(--secondary-h-s), var(--l-30));
    border-left: 5px solid hsl(var(--secondary-h-s), var(--l-50));
    border-radius: 8px;
    margin: 0;
    padding: 0px var(--padding) 0px calc(2 * var(--padding));
    background: hsl(var(--secondary-h-s), var(--l-10));
  }

  .twitter-tweet {
    margin: 0 auto;
  }

  a:link,  a:hover, a:active {
    font-weight: 600;
    color: hsl(var(--secondary-accent-h-s), var(--l-70));
  }
  
  a:visited {
    font-weight: 600;
    color: hsl(var(--secondary-h-s), var(--l-90));
  }
}

/* Sticky Gradient Helpers */
.rs-gradient-top {
  position: sticky;
  top: calc(-1 * var(--padding));
  left: calc(-1 * var(--padding));
  right: calc(-1 * var(--padding));
  height: calc(2 * var(--padding));
  background: linear-gradient(
    to bottom,
    hsla(var(--primary-h-s), var(--l-10), 1) 30%,
    hsla(var(--primary-h-s), var(--l-10), 0)
  );
}

.rs-gradient-bottom {
  position: sticky;
  bottom: calc(-1 * var(--padding));
  left: calc(-1 * var(--padding));
  right: calc(-1 * var(--padding));

  background: linear-gradient(
    to top,
    hsla(var(--primary-h-s), var(--l-10), 1) 30%,
    hsla(var(--primary-h-s), var(--l-10), 0)
  );
  height: calc(2 * var(--padding));
}


/* Scrollbar Helpers */
 /* Hide scrollbar */
 .rs-hide-scrollbar::-webkit-scrollbar {
  display: none;
}

/* Hide scrollbar for IE, Edge and Firefox */
.rs-hide-scrollbar {
  -ms-overflow-style: none; /* IE and Edge */
  scrollbar-width: none; /* Firefox */
}

/* LED Indicators - adapted from https://github.com/aus/led.css */
.led {
  margin: 0 auto;
  width: 0.6rem;
  height: 0.6rem;
  border-radius: 50%;
  box-shadow: var(--led-shadow) 0 -1px 6px 1px;
}
.led-red {
  background-color: #f00;
  box-shadow: var(--led-shadow) 0 -1px 4px 1px, inset #600 0 -1px 3px, #f00 0 1px 6px;
}
.led-orange {
  background-color: #ff7000;
  box-shadow: var(--led-shadow) 0 -1px 4px 1px, inset #630 0 -1px 3px, #ff7000 0 1px 6px;
}

.led-yellow {
  background-color: #ff0;
  box-shadow: var(--led-shadow) 0 -1px 4px 1px, inset #660 0 -1px 3px, #ff0 0 1px 6px;
}

.led-green {
  background-color: #80ff00;
  box-shadow: var(--led-shadow) 0 -1px 4px 1px, inset #460 0 -1px 3px, #80ff00 0 1px 6px;
}

.led-blue {
  background-color: #06f;
  box-shadow: var(--led-shadow) 0 -1px 4px 1px, inset #006 0 -1px 3px, #06f 0 1px 6px;
}

.led-purple {
  background-color: #9300ff;
  box-shadow: var(--led-shadow) 0 -1px 4px 1px, inset #006 0 -1px 3px, #9300ff 0 1px 6px;
}
